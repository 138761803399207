

/* ========== INITIALISATION DES POLICE DE TEXTE ========== */

@font-face {
	font-family: 'lebontechnicien-Montserrat-Medium';
	src: url('../Assets/font/Montserrat-Medium.woff') format('woff'),

		url('../Assets/font/Montserrat-Medium.woff2') format('woff2'),
		url('../Assets/font/Montserrat-Medium.svg#Montserrat-Medium') format('svg'),
		url('../Assets/font/Montserrat-Medium.eot'),
		url('../Assets/font/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
		url('../Assets/font/Montserrat-Medium.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'lebontechnicien-Montserrat-Regular';
	src: url('../Assets/font/Montserrat-Regular.woff') format('woff'),
		url('../Assets/font/Montserrat-Regular.woff2') format('woff2'),
		url('../Assets/font/Montserrat-Regular.svg#Montserrat-Regular') format('svg'),
		url('../Assets/font/Montserrat-Regular.eot'),
		url('../Assets/font/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('../Assets/font/Montserrat-Regular.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'lebontechnicien-Montserrat-Bold';
	src: url('../Assets/font/Montserrat-Bold.woff') format('woff'),
		url('../Assets/font/Montserrat-Bold.woff2') format('woff2'),
		url('../Assets/font/Montserrat-Bold.svg#Montserrat-Bold') format('svg'),
		url('../Assets/font/Montserrat-Bold.eot'),
		url('../Assets/font/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('../Assets/font/Montserrat-Bold.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'lebontechnicien-Montserrat-Light';
	src: url('../Assets/font/Montserrat-Light.woff') format('woff'),
		url('../Assets/font/Montserrat-Light.woff2') format('woff2'),
		url('../Assets/font/Montserrat-Light.svg#Montserrat-Light') format('svg'),
		url('../Assets/font/Montserrat-Light.eot'),
		url('../Assets/font/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('../Assets/font/Montserrat-Light.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'lebontechnicien-Poppins-Regular';
	src: url('../Assets/font/Poppins-Regular.woff') format('woff'),
		url('../Assets/font/Poppins-Regular.woff2') format('woff2'),
		url('../Assets/font/Poppins-Regular.svg#Poppins-Regular') format('svg'),
		url('../Assets/font/Poppins-Regular.eot'),
		url('../Assets/font/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
		url('../Assets/font/Poppins-Regular.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'lebontechnicien-Poppins-Bold';
	src: url('../Assets/font/Poppins-Bold.woff') format('woff'),
		url('../Assets/font/Poppins-Bold.woff2') format('woff2'),
		url('../Assets/font/Poppins-Bold.svg#Poppins-Bold') format('svg'),
		url('../Assets/font/Poppins-Bold.eot'),
		url('../Assets/font/Poppins-Bold.eot?#iefix') format('embedded-opentype');
	/*url('../Assets/font/Poppins-Bold.ttf') format('truetype');*/

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'lebontechnicien-Poppins-Light';
	src: url('../Assets/font/Poppins-Light.woff') format('woff'),
		url('../Assets/font/Poppins-Light.woff2') format('woff2'),
		url('../Assets/font/Poppins-Light.svg#Poppins-Light') format('svg'),
		url('../Assets/font/Poppins-Light.eot'),
		url('../Assets/font/Poppins-Light.eot?#iefix') format('embedded-opentype'),
		url('../Assets/font/Poppins-Light.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'lebontechnicien-Poppins-Medium';
	src: url('../Assets/font/Poppins-Medium.woff') format('woff'),
		url('../Assets/font/Poppins-Medium.woff2') format('woff2'),
		url('../Assets/font/Poppins-Medium.svg#Poppins-Medium') format('svg'),
		url('../Assets/font/Poppins-Medium.eot'),
		url('../Assets/font/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
		url('../Assets/font/Poppins-Medium.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* ========== RÉINITIALISATION DES BALISES ========== */

/* {
    margin: 0;
    padding: 0;
	border: 0;
    box-sizing: border-box;
}

.leBonTech-html, .leBonTech-body, .leBonTech-div, .leBonTech-span, .leBonTech-applet, .leBonTech-object, .leBonTech-iframe, .leBonTech-video, .leBonTech-audio,
.leBonTech-h1, .leBonTech-h2, .leBonTech-h3, .leBonTech-h4, .leBonTech-h5, .leBonTech-h6, .leBonTech-p, .leBonTech-blockquote, .leBonTech-pre,
.leBonTech-a, .leBonTech-abbr, .leBonTech-acronym, .leBonTech-address, .leBonTech-big, .leBonTech-cite, .leBonTech-code,
.leBonTech-del, .leBonTech-dfn, .leBonTech-em, .leBonTech-font, .leBonTech-ins, .leBonTech-kbd, .leBonTech-q, .leBonTech-s, .leBonTech-samp,
.leBonTech-small, .leBonTech-strike, .leBonTech-strong, .leBonTech-sub, .leBonTech-sup, .leBonTech-tt, .leBonTech-var,
.leBonTech-dl, .leBonTech-dt, .leBonTech-dd, .leBonTech-ol, .leBonTech-ul, .leBonTech-li,
.leBonTech-fieldset, .leBonTech-form, .leBonTech-label, .leBonTech-legend, .leBonTech-input, .leBonTech-textarea, .leBonTech-button,
.leBonTech-table, .leBonTech-caption, .leBonTech-tbody, .leBonTech-tfoot, .leBonTech-thead, .leBonTech-tr, .leBonTech-th, .leBonTech-td,
.leBonTech-article, .leBonTech-aside, .leBonTech-address, .leBonTech-section {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  font-weight: inherit;
  font-style: inherit;
  outline: 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}


html {
	font-family: 'lebontechnicien-Poppins-Regular';
	font-size: 100%;              	          		
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;                          	
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}*/

html,
body,
main {

	margin: 0 auto;
	padding: 0;
	width: 100%;
}

main {
	max-width: 1280px;
	padding: 0 10px;
}

.leBonTech-h1 {
	font-family: 'lebontechnicien-Montserrat-Bold';
	font-size: 38px;
	line-height: 1.6;
	margin: 40px 0;
}

.leBonTech-h2 {
	font-family: 'lebontechnicien-Montserrat-Bold';
	font-size: 34px;
	line-height: 1.5;
	margin: 35px 0;
}

.leBonTech-h3 {
	font-family: 'lebontechnicien-Montserrat-Medium';
	font-size: 30px;
	line-height: 1.4;
	margin: 35px 0;
}

.leBonTech-h4 {
	font-family: 'lebontechnicien-Montserrat-Medium';
	font-size: 26px;
	line-height: 1.4;
	margin: 30px 0;
}

.leBonTech-h5 {
	font-family: 'lebontechnicien-Montserrat-Medium';
	font-size: 22px;
	line-height: 1.4;
	margin: 25px 0;
}

.leBonTech-h6 {
	font-family: 'lebontechnicien-Montserrat-Medium';
	font-size: 18px;
	line-height: 1.4;
	margin: 20px 0;
}

.leBonTech-p {
	font-family: 'lebontechnicien-Poppins-Regular';
	font-size: 16px;
	line-height: 1.4;
	margin: 20px 0;
	color: #000000;
}

.leBonTech-a {
	font-family: 'lebontechnicien-Poppins-Regular';
	text-decoration: none;
	font-size: 16px;
	line-height: 1.4;
	margin: 20px 0;
	color: #3e9f36;
}

blockquote {
	font-family: 'lebontechnicien-Poppins-Regular';
	font-size: 16px;
	color: #000000;
}

pre {
	font-family: 'lebontechnicien-Poppins-Regular';
	font-size: 13px;
	color: #000000;
}

.leBonTech-section {
	padding: 0 40px;
	max-width: 1280px;
	margin: auto;
}

.leBonTech-h1 {
	text-align: center;
	color: #0f8203;
}

.leBonTech-h2,
.leBonTech-h3,
.leBonTech-h4 {
	text-align: center;
	color: #3e9f36;
}


.partner-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	gap: 50px;
}

.partner-item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.partner-item-img {
	width: 150px !important;
	/* Adjust the width as needed */
	height: 150px !important;
	/* Adjust the height as needed */
	object-fit: contain;
}

.partner-grid-wrapper {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}



/* ========== STYLE DU HEADER ========== */
header {
	padding: 20px;
}


/* Style du logo */
.logo-header {
	width: 23%;
	text-align: left;
	float: left;
}

.logo-header img {
	display: block;
	width: 70%;
}

/* Style général de la navigation */

.main-menu {
	width: 77%;
	float: left;
}

nav {
	background-color: transparent;
	justify-content: space-between;
	align-items: center;
	padding: 9px;
}

/* Style de la liste de navigation */

nav ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: end;
}

/* Style des éléments de la liste de navigation */
nav li {
	margin-left: 20px;
}

.main-nav nav li {
	border-left: 1px solid #000;
	padding: 0 0px 0 20px;
}

.main-nav nav li:first-child {
	border-left: none !important;
}

/* Style des liens de navigation */
nav .leBonTech-a {
	text-decoration: none;
	color: #000000;
	font-size: 14px;
	transition: color 0.5s ease;
}

/* Changement de couleur des liens de navigation lors du survol */

.main-nav {
	float: left;
	width: 75%;
	text-align: right;
}

.main-nav nav a::after {
	content: "";
	display: block;
	height: 0.1rem;
	background: #000000;
	transform: scale(0);
	transition: transform 0.6s;
}

.main-nav nav a:hover::after {
	transform: scale(1);
	transform-origin: left;
}

.login-nav {
	float: left;
	width: 25%;

	text-align: right;
	margin-top: -8px;
}

.login-nav .btn-inscription a,
.login-nav .btn-connection a {
	color: #ffffff;
}

.login-nav .btn-inscription,
.login-nav .btn-connection {
	background: #000000;
	border-style: solid;
	border-width: 2px;
	border-color: #000000;
	border-radius: 7px;
	padding: 5px 10px;
	transition: background 0.5s ease, border-color 0.5s ease;
}

.login-nav .btn-inscription:hover a,
.login-nav .btn-connection:hover a {
	color: #000000 !important;
}

.login-nav .btn-inscription:hover,
.login-nav .btn-connection:hover {
	background: transparent !important;
	border-color: #000000;

}

/* ========== STYLE DE LA BANNIERE =========== */

.banner {
	padding: 0 20px;
}

.animated-figure ul li {
	list-style-type: none;
	position: absolute;
	z-index: -1;
	top: -300px;
	right: 0;
}

.animated-figure ul li:first-child {
	top: -310px;
}

.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.compress-left-side {
	padding-right: 50px;
	padding-left: calc(calc(100% - 1170px)/2);
}

.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}

.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}

.banner-info p {
	width: 90%;
	margin-bottom: 50px;
}

.order-xl-1 {
	-ms-flex-order: 1;
	order: 1;
}

.order-xl-2 {
	-ms-flex-order: 2;
	order: 2;
}

.col-xl-6 {
	-ms-flex: 0 0 50%;
	position: relative;
	flex: 0 0 50%;
	max-width: 50%;
}


.item-main-title {
	text-align: left;
	line-height: 1.4;
}

.banner-btns {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: -10px;
}

.banner-btns .item-btn {
	min-width: 180px;
	margin-bottom: 10px;
	margin-right: 30px;
	padding: 9px 29px;
	border: 1px solid #e6e6e6;
}

.btn-ghost.btn-dark {
	border-color: #1d2124;
	color: #1d2124;
	background-color: transparent;
}

.btn-ghost {
	font-weight: 500;
	font-size: 16px;
	position: relative;
	z-index: 2;
	padding: 10px 27px;
	border-radius: 4px;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
	border: 2px solid;
	border-color: #5a49f8;
	color: #5a49f8;
	-webkit-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.btn-ghost i:before {
	font-size: 18px;
}

.banner-btns .btn-ghost img {
	margin-right: 10px;
	margin-left: 0;
}

.btn-ghost.btn-dark i {
	color: #1d2124;
}

.item-figure img {
	max-width: 70%;
}

/* ========== STYLE DE LA HOME ========= */

section {
	margin-bottom: 40px !important;
}

.container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.column {
	flex: 1;
	padding: 0 10px;
	margin: 0 10px;
	text-align: center;
	position: relative;
}

.image {
	width: 100%;
	height: auto;
}

.p-accroche {
	text-align: left;
	font-size: 20px;
}

.comment-ca-marche,
.aventage-client,
.aventage-tech,
.valeurs,
.prestation {
	margin-bottom: 50px;
}

.comment-ca-marche .column {
	padding: 0 20px;
	text-align: center;
}

.comment-ca-marche .column img,
.prestation .column img {
	margin: auto;
	display: block;
	height: 57px;
	margin-bottom: 15px;
}

.comment-ca-marche .column:after,
.prestation .column:after,
.valeurs .column:after {
	content: "";
	width: 1px;
	height: 50%;
	background-color: #d9d9d9;
	right: -15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	position: absolute;
	z-index: 1;
}

.comment-ca-marche .column:last-child:after,
.prestation .column:last-child:after,
.valeurs .column:last-child:after {
	display: none;
}

.comment-ca-marche .column h3,
.prestation .column h3,
.valeurs .column h3 {
	font-family: 'lebontechnicien-Montserrat-Medium';
	color: #000000;
	font-weight: bold;
	font-size: 24px;
	line-height: 1.2;
	margin-top: 5px;
	margin-bottom: 15px;
}

.comment-ca-marche .p-accroche,
.prestation .p-accroche,
.valeurs .p-accroche {
	text-align: center !important;
	margin-bottom: 35px;
}

.aventage-client p,
.aventage-tech p {
	text-align: justify;
}

.aventage-client .p-accroche,
.aventage-tech .p-accroche {
	font-weight: bold;
}



.aventage-client,
.aventage-client h2,
.aventage-tech,
.aventage-tech h2 {
	text-align: left;
}

.aventage-client img.image,
.aventage-tech img.image {
	width: 70%;
	margin-left: 80px;
}

.aventage-client-container {
	justify-content: center; 
	align-items: center; 
}

.image-container {
	display: flex;
	justify-content: center; /* Center image horizontally */
	align-items: center; /* Center image vertically */
  }

  .aventage-client-image-container img.image {
	max-width: 100%  !important; /* Make sure the image fits within its container */
	height: auto; 
  }




/* ========== STYLE DE LA PAGE ACTIVITES ========== */

#equipements h2,
#experts h2 {
	text-align: left;
}

.columns {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: left;
}

.columns>div {
	flex: 1;
	padding: 20px;
	min-width: 250px;
	max-width: 350px;
	border-radius: 5px;
	position: relative;

}


.columns>div:after {
	content: "";
	width: 1px;
	height: 50%;
	background-color: #d9d9d9;
	right: -15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	position: absolute;
	z-index: 1;
}

.columns>div:nth-child(4n):after {
	display: none;
}

.columns>div:nth-child(14):after {
	display: none;
}

.columns>div h3 {
	font-family: 'lebontechnicien-Montserrat-Medium';
	color: #064a00;
	font-weight: bold;
	font-size: 16px;
	line-height: 1.2;
	margin-top: 5px;
	margin-bottom: 5px;
	text-align: left;
}

.columns>div h3 {
	cursor: pointer;
}

.columns>div p {
	font-family: 'lebontechnicien-Poppins-Regular';
	font-size: 13px;
	line-height: 1.4;
	margin: 5px 0 0 0;
	color: #000000;
	text-align: left;
}



.columns>div img {
	max-height: 30px;
	float: left;
	margin-right: 20px;
	margin-top: 10px;
}

/* ========== STYLE PAGE COMMENT CA MARCHE ========== */

.activites-section h2 {
	color: #064a00;
	text-align: left;
}

.activites-section p {
	text-align: left;
}

.activites-section img {
	max-width: 50%
}

.activites-section img,
.activites-section .choix-tech,
.activites-section .contact-tech,
.activites-section .nouvelle-demande {
	float: left;
}

.activites-section .choix-tech,
.activites-section .contact-tech,
.activites-section .nouvelle-demande {
	max-width: 45%;
	margin-left: 1%;
	margin-right: 1%;
}

/* ========== STYLE PAGE CHARTE DE QUALITE ========== */

.charte-qualite h2 {
	color: #064a00;
	text-align: left;
}

/* ========== STYLE PAGE CGV , CGU et POLITIQUE DE CONFIDENTIALITÉ ========== */

.cgv h1,
.cgu h1,
.pdc h1,
.pgc h1 {
	color: #0f8203;
	text-align: center;
	text-decoration: underline;
}

.cgv h2,
.cgu h2,
.pdc h2,
.pgc h2 {
	color: #064a00;
	text-align: left;
}

.cgv span,
.cgu span,
.pdc span,
.pgc span {
	color: #064a00;
	text-align: left;
	font-style: italic;
	font-weight: bold;
}

.cgv ul li,
.cgu ul li,
.pdc ul li,
.pgc ul li {
	list-style-type: none;
}


/* ========== STYLE DU FOOTER ========== */

/* Style général du pied de page */
footer {
	background-color: #000000;
	color: #fff;
	padding: 20px 20px 5px 20px;
	flex-shrink: 0; 
}

footer h1,
footer h2,
footer h3,
footer h1 {
	color: #3e9f36;
}

.footer-content {
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
}

.footer-bottom {
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	color: #ffffff;
	font-size: 11px;
}

/* Style du contenu du pied de page */
.footer-content {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

/* Style des sections du pied de page */
.footer-section {
	flex: 1;
	margin: 0 auto;
	align-items: center
}

/* Style des titres des sections du pied de page */
.footer-section h3 {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 1rem;
}

/* Style des liens et listes du pied de page */
.footer-section ul {
	font-family: 'lebontechnicien-Poppins-Light';
	list-style-type: none;
	color: #ffffff;
	text-align: center;
	display: flex;
	margin: 0 20px;
	justify-content: center
}

.footer-section ul li {
	font-family: 'lebontechnicien-Poppins-Light';
	list-style-type: none;
	color: #ffffff;
	text-align: center;
	margin: 0 20px;
}

.footer-section a {
	font-family: 'lebontechnicien-Poppins-Light';
	color: #fff;
	text-decoration: none;
	font-size: 13px;
	display: block;
	transition: color 0.3s ease;
}

.footer-section p {
	font-family: 'lebontechnicien-Poppins-Light';
	text-align: center;
	color: #fff;
	font-size: 13px;
	text-decoration: none;
	display: block;
	margin-bottom: 10px;
	margin-top: 10px;
}

/* Changement de couleur des liens du pied de page lors du survol */
.footer-section a:hover {
	color: #3e9f36;
}

/* Style de la section de bas du pied de page */
.footer-bottom {
	text-align: center;
	font-size: 10px;
	margin-top: 20px;
	color: #ffffff;
}

.footer-bottom p {
	font-size: 10px;
	color: #ffffff;
	margin-bottom: 5px;
	margin-top: 10px;
}


.logo-footer {
	max-width: 200px;
	margin: auto;
}


/*form {
	display: flex;
	flex-direction: column;
	max-width: 400px;
	margin: 0 auto;
}


label {
	font-weight: bold;
}

input,
textarea {
	width: 100%;
	padding: 0.5rem;
	border: 1px solid #ccc;
	border-radius: 4px;
}

button {
	background-color: #f50057;
	color: #fff;
	border: none;
	padding: 0.5rem 1rem;
	border-radius: 4px;
	cursor: pointer;
}*/

.card-system {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 1200px;
	margin: 0 auto;
	background-color: #ffffff;
	padding: 20px;
	gap: 20px;
}

.card {
	background-color: #F8F8F8;
	border-radius: 8px;
	padding: 20px;
	margin: 10px;
	width: 30%;
}

.maxName {
	max-width: 40% !important;
	margin-left: 20px;
}


@media screen and (max-width: 768px) {

	.banner-btns .item-btn {
		max-width: 145px !important; 
		padding: 9px 5px !important;
	}
	.container {
		padding-left: 0 !important; 
		padding-right: 0 !important;
	}
	.column { padding: 0 !important; 
		margin: 10px 0 !important;
	}

	.card {
		width: 100%;
	}


	.partner-grid {
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
		grid-gap: 70px;
	}

	/*========== menu ========*/
	header {
		padding: 0;
	}

	.logo-header img {
		width: 100%;
	}

	.logo-header {
		width: 60%;
		margin: 10px 20px;
	}

	#burger-menu {
		cursor: pointer;
		height: 27px;
		width: 27px;
		margin: 20px;
		overflow: visible;
		position: relative;
		z-index: 20;
		float: right;
	}

	#burger-menu span,
	#burger-menu span:before,
	#burger-menu span:after {
		background: #000;
		display: block;
		height: 2px;
		opacity: 1;
		position: absolute;
		transition: 0.3s ease-in-out;
	}

	#burger-menu span:before,
	#burger-menu span:after {
		content: "";
	}

	#burger-menu span:before {
		left: 0px;
		top: -10px;
		width: 27px;
	}

	#burger-menu span {
		right: 0px;
		top: 13px;
		width: 27px;
	}

	#burger-menu span:after {
		left: 0px;
		top: 10px;
		width: 27px;
	}

	#burger-menu.close span:before {
		background: #ffffff !important;
		top: 0px;
		transform: rotate(90deg);
		width: 27px;
	}

	#burger-menu.close span {
		background: #ffffff !important;
		transform: rotate(-45deg);
		top: 13px;
		width: 27px;
	}

	#burger-menu.close span:after {
		background: #ffffff !important;
		top: 0px;
		left: 0;
		transform: rotate(90deg);
		opacity: 0;
		width: 0;
	}

	.main-menu {
		width: 100%;
	}

	#menu {
		z-index: 10;
		min-width: 100%;
		min-height: 100%;
		position: fixed;
		top: 0;
		height: 0;
		visibility: hidden;
		opacity: 0;
		text-align: center;
		padding-top: 20px;
		transition: all 0.3s ease-in-out;
	}

	#menu.overlay {
		visibility: visible;
		opacity: 1;
		padding-top: 80px;
		background: #000000;
	}

	#menu ul {
		padding: 0;
		display: block;
	}

	#menu li {
		list-style: none;
	}

	#menu a {
		color: #ffffff;
		display: block;
		font-size: 16px;
		margin-bottom: 5px;
		text-decoration: none;
	}

	.main-nav {

		float: none;
		width: 100%;
		text-align: left;
	}

	.login-nav {

		float: none;
		width: 100%;
		text-align: left;
		margin-top: 0;
	}

	.login-nav .btn-inscription,
	.login-nav .btn-connection {
		background: transparent;
		border-style: none;
		border-width: 0;
		border-color: transparent;
		border-radius: 0;
		padding: 0;
		transition: none;
	}

	.main-nav nav a::after {
		display: none;
	}

	nav {
		padding: 0;
	}

	#menu nav li a,
	#menu .login-nav li a {
		margin-bottom: 10
	}

	.main-nav nav li {
		border-left: none;
		padding: 0 !important;
	}

	form {
		max-width: 100%;
	}

	/*========================*/
	h2 {
		font-size: 28px;
		line-height: 1.2;
		margin: 25px 0;
	}

	.p-accroche {
		font-size: 18px;
	}

	section {
		padding: 0 15px;
	}

	.animated-figure ul li {
		display: none
	}

	.animated-figure ul li:first-child {
		display: none
	}

	.row {
		display: block;
		margin-right: 0;
		margin-left: 0;
	}

	.compress-left-side {
		padding: 0;
	}

	.align-items-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}

	.d-flex {
		display: block !important;
		display: block !important;
	}

	.banner-info p {
		font-size: 14px;
		width: 100%;
		margin-bottom: 20px;
		text-align: justify;
	}

	.order-xl-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.order-xl-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.col-xl-6 {

		position: relative;
		flex: 100%;
		max-width: 100%;
	}

	.aventage-client img.image,
	.aventage-tech img.image {
		margin-left: 0px !important;
	}


	.item-main-title {
		font-size: 32px;
		text-align: left;
		line-height: 1.2;
	}

	.banner-btns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-bottom: -10px;
		gap: 10px;
	}

	.item-figure {
		display: none
	}

	.banner-btns .item-btn {
		width: 190px;
		margin-right: 5px;
	}

	.banner-btns .btn-1 {
		margin: auto !important;
	}

	.banner-btns .btn-2 {
		margin: auto !important;

	}


	.column {
		flex: 100%;
		margin: 10px 10px;
	}

	.comment-ca-marche .column:after,
	.prestation .column:after,
	.valeurs .column:after {
		content: "";
		width: 50%;
		height: 1px;
		background-color: #d9d9d9;
		display: block;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		position: relative;
		z-index: 1;
		top: inherit;
		right: inherit;
		margin: auto;
	}

	.aventage-client img.image,
	.aventage-tech img.image {
		width: 100%;
	}

	.columns>div {
		flex: 100%;

	}


	.columns>div:after {
		content: "";
		width: 50%;
		height: 1px;
		background-color: #d9d9d9;
		display: block;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		position: relative;
		z-index: 1;
		top: inherit;
		right: inherit;
		margin: auto;
	}

	.activites-section img,
	.activites-section .choix-tech,
	.activites-section .contact-tech,
	.activites-section .nouvelle-demande {
		max-width: 100%;
		margin-top: 1%;
		margin-bottom: 1%;
	}

	#equipements .columns>div:after,
	#experts .columns>div:after {
		display: none;
	}

	.green {
		color: #0f8203;
	}

	/*======== footer ========*/
	.footer-section ul {
		display: block;
	}

	.banner-btns .item-btn {
		max-width: 145px;
		padding: 9px 5px;
	}

	.container {
		padding-left: 0;
		padding-right: 0;
	}

	.column {
		padding: 0;
		margin: 10px 0;
	}

	.maxName {
		margin-left: 0px !important;
		max-width: 80% !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

	/*========== menu ========*/
	header {
		padding: 0;
		height: 80px;
	}

	.logo-header img {
		width: 100%;
		margin-top: 20px;
		margin-left: 20px;
	}

	#menu {
		margin-top: 20px;
	}

	#menu nav li a {
		font-size: 14px;
	}

	.main-nav {
		width: 65%;
	}

	.login-nav {
		width: 35%;
	}

	nav li {
		margin-left: 3px;
		margin-right: 3px;
	}

	.main-nav nav li {
		padding: 0 5px 0 10px;
	}

	.login-nav .btn-inscription a,
	.login-nav .btn-connection a {
		font-size: 11px !important;
	}


	/*========================*/
	h2 {
		font-size: 28px;
		line-height: 1.2;
		margin: 25px 0;
	}

	.p-accroche {
		font-size: 18px;
	}

	section {
		padding: 0 15px;
	}

	.animated-figure ul li {
		display: none
	}

	.animated-figure ul li:first-child {
		display: none
	}

	.row {
		display: block;
		margin-right: 0;
		margin-left: 0;
	}

	.compress-left-side {
		padding: 0;
	}

	.align-items-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}

	.d-flex {
		display: block !important;
		display: block !important;
	}

	.banner-info {
		margin-top: 50px;
	}

	.banner-info p {
		font-size: 14px;
		width: 100%;
		margin-bottom: 20px;
		text-align: justify;
	}

	.order-xl-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.order-xl-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.col-xl-6.compress-left-side {
		max-width: 50%;
	}

	.col-xl-6 {

		position: relative;
		flex: 100%;
		max-width: 40%;
		float: left;
		margin: 2%;

	}


	.item-main-title {
		font-size: 32px;
		text-align: left;
		line-height: 1.2;
	}

	.banner-btns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-bottom: -10px;
	}

	.item-figure {
		display: block;
	}

	.banner-btns .item-btn {
		min-width: 170px;
		margin-right: 5px;
	}

	form {
		max-width: 600px;
	}

	.maxName {
		margin-left: 0px !important;
		max-width: 80% !important;
	}

}

@media screen and (min-width: 1024px) and (max-width: 1280px) {

	/*========== menu ========*/
	header {
		padding: 0;
		height: 80px;
	}

	.logo-header img {
		width: 100%;
		margin-top: 20px;
		margin-left: 20px;
	}

	#menu {
		margin-top: 20px;
	}

	#menu nav li a {
		font-size: 13px;
	}

	.main-nav {
		width: 65%;
	}

	.login-nav {
		width: 35%;
	}

	nav li {
		margin-left: 3px;
		margin-right: 3px;
	}

	.main-nav nav li {
		padding: 0 5px 0 10px;
	}

	.login-nav .btn-inscription a,
	.login-nav .btn-connection a {
		font-size: 11px !important;
	}


	/*========================*/
	h2 {
		font-size: 28px;
		line-height: 1.2;
		margin: 25px 0;
	}

	.p-accroche {
		font-size: 18px;
	}

	section {
		padding: 0 15px;
	}

	.animated-figure ul li {
		display: none
	}

	.animated-figure ul li:first-child {
		display: none
	}

	.row {
		display: block;
		margin-right: 0;
		margin-left: 0;
	}

	.compress-left-side {
		padding: 0;
	}

	.align-items-center {
		-ms-flex-align: center !important;
		align-items: center !important;
	}

	.d-flex {
		display: block !important;
		display: block !important;
	}

	.banner-info {
		margin-top: 100px;
	}

	.banner-info p {
		font-size: 14px;
		width: 100%;
		margin-bottom: 20px;
		text-align: justify;
	}

	.order-xl-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.order-xl-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.col-xl-6.compress-left-side {
		max-width: 50%;
	}

	.col-xl-6 {

		position: relative;
		flex: 100%;
		max-width: 40%;
		float: left;
		margin: 2%;

	}


	.item-main-title {
		font-size: 32px;
		text-align: left;
		line-height: 1.2;
	}

	.banner-btns {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-bottom: -10px;
	}

	.item-figure {
		display: block;
	}

	.banner-btns .item-btn {
		min-width: 170px;
		margin-right: 5px;
	}

	form {
		max-width: 800px;
	}

	.maxName {
		margin-left: 0px !important;
		max-width: 40% !important;
	}

	.item-figure img {
		margin-top: 100px !important;
		max-width: 70%;
	}
}