


h1 {
    font-size: 24px;
    font-weight: 600;
}
h2 {
    font-size: 22px;
    font-weight: 600;
}
h3 {
    font-size: 20px;
    font-weight: 600;
}
h4 {
    font-size: 18px;
    font-weight: 600;
}
  
  /* Header */
  .Wrapper {
    display: flex;
    justify-content: flex-start;
  }
  
  .Header {
    text-align: start;
    background-color: #f1f6fa;
    position: relative;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 146px;
    padding-bottom: 90px;
  }
  
  .Header-Container {
    position: relative;
    height: 100%;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .Header-title {
    font-weight: 100;
    position: relative;
    color: rgb(50, 61, 71);
    letter-spacing: 0.5px;
    font-size: 41.2501px;
    margin-bottom: 8px;
    font-family: Barlow, sans-serif;
    line-height: 61.8751px;
    margin-top: 0px;
    box-sizing: border-box;
  }
  
  .Header-paragraph {
    font-size: 18px;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  
  /*Content*/
  
  aside {
    /*width: 30%;*/
    border-right: 1px solid #e9ecf0;
    padding-right: 16px;
    padding-top: 16px;
    border-right: 0.8px solid rgb(241, 242, 243);
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 8587.62px;
    padding-top: 80px;
    padding-bottom: 80px;
    z-index: 900;
    transition: all 0.3s ease-out 0s;
    box-sizing: border-box;
  }
  
  .Aside-content {
    position: relative;
    padding-left: 40px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    overflow-anchor: none;
    touch-action: auto;
    /*overflow: hidden;*/
    box-sizing: border-box;
  }
  
  .menuItems-icon {
    font-family: themify;
    font-style: normal;
    font-size: 12px;
    display: block;
    line-height: 24px;
    transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
    color: rgb(117, 117, 117);
    box-sizing: border-box;
    margin-top: 4px;
  }

  .Aside {
    position: sticky;

    max-height: calc(100vh - 80px); /* Adjust as needed */
  }

  .Aside-content-ul-a.active {
    color: green !important;
    font-weight: 800 !important;
  }
  
  .Main-Wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    box-sizing: border-box;
    border-top: 1px solid #e5e7eb;
    margin-bottom: 50px;
  }
  
  .Aside-Wrapper {
    flex: 0 0 25%;
    max-width: 30%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .Aside-content-ul {
    height: 8427.62px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0px;
    margin-bottom: 0px;
    list-style: outside none none;
    margin-top: 0px;
    box-sizing: border-box;
  }
  
  .Aside-content-ul-a {
    font-weight: 400;
    font-size: 18px;
    padding: 8px 0px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    line-height: 27px;
    transition: all 0.2s linear 0s;
    text-decoration: none solid rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
  }
  
  /*Main Content */
  
  .Main-Content {
    flex: 0 0 66.6667%;
    max-width: 70%;
    position: relative;
    width: 100%;
    min-height: 1px;
    text-align: start;
    padding: 0;
    box-sizing: border-box;
  }

    ul.subItems-subsection, ul.subItems-list {
        margin-left: 10px;
        padding: 10px;
    }



    li.subItem, li.dropdown {
        margin-bottom: 10px;
    }



    /*li.dropdown a:hover {
        color: #0f8203;
        font-weight: 700;   
    }*/
  
  .Main-Content-Scroll {
    max-height: calc(100vh - 80px); /* Adjust as needed */
    overflow-y: auto; /* Enable vertical scroll */
    padding: 20px; /* Add padding for spacing */
    /* Hide the default scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  /* Style the custom scrollbar track and thumb */
  .Main-Content-Scroll::-webkit-scrollbar {
    width: 0.5rem; /* Width of the custom scrollbar */
  }
  
  .Main-Content-Scroll::-webkit-scrollbar-track {
    background: transparent; /* Background of the scrollbar track */
  }
  
  .Main-Content-Scroll::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0); /* Color of the scrollbar thumb */
    border-radius: 0.25rem; /* Radius of the scrollbar thumb */
  }
  
  .activeSubmenu {
    /* Add styles to highlight the active submenu */
    background-color: green;
    color: white;
  }
  
  main {
    width: 70%;
  }
  
  /* Media Query */
  
  @media (min-width: 2516px) {
    .Header-Container {
      max-width: 1140px;
    }
  }
  
  @media (min-width: 298px) and (max-width: 2515px) {
    .Header-Container {
      max-width: 960px;
    }
  }
  
  @media (min-width: 298px) and (max-width: 1200px) {
    .Header-Container {
      max-width: 720px;
    }
  }
  
  @media (max-width: 576px) {
    .Header {
      text-align: center;
    }
    .Header-Container {
      max-width: 100%;
      height: 100%;
      padding-right: 0px !important;
      padding-left: 0px !important;
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
  
    .Aside-Wrapper {
      display: none;
    }
  
    .Main-Wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-right: 0px !important;
      margin-left: 0px !important;
      box-sizing: border-box;
    }
  
    .Main-Content {
      flex: none;
      padding-right: 0px;
      padding-left: 0px;
      max-width: 100%;
    }
  }
  
  @media (max-width: 991.98px) {
    .Aside-content {
      padding-left: 10px;
    }
  }
  