@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

:root {

    --Primary1: #1D3164;
    --Primary2: #F4661B;
    --Primary3: #22780F;
    --Secondary1: #000;
    --Secondary2: #fff;
    --Secondary3: #FFCB60;
    --Secondary4: #777777;

    --Header1: 34px;
    --Header2: 28px;
    --Header3: 20px;
    --Header4: 14px;
    --Header5: 12px;
    --Header6: 10px;
    --Body: 16px;

    --borderRadius: 10px;

}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'CenturyGothic';
}

@font-face {
    font-family: 'Audrey';
    src: url('../Assets/Fonts/Audrey-Normal.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Audrey-bold';
    src: url('../Assets/Fonts/Audrey-Bold.otf') format('truetype');
    font-weight: bold;
    font-style: bold;
  }  
  
  @font-face {
    font-family: 'CenturyGothic';
    src: url('../Assets/Fonts/CenturyGothic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  

.title-font {
    font-family: 'Audrey-bold';
}

.title-font-h2 {
    font-family: 'Audrey-bold';
    font-size: 32px;
}

.title-font-h3 {
    font-family: 'Audrey-bold';
    font-size: 28px;
}