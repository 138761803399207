@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@300;400;700&display=swap");

body {
  font-family: "Inter", sans-serif;
}

/* Add this rule to target specific elements if needed */
h1,
h2,
h3,
h4,
h5,
h6,
p,
button {
  font-family: "Inter", sans-serif;
}

.big-container {
  display: flex;
  z-index: 1;
  width: 100%;

  justify-content: space-between;
  flex-wrap: wrap;
}

.how-app-work-slider-wrapper {
  position: relative;
  width: 305px;
  height: 655px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
}

.carousel-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://html.themexriver.com/appilo-update/img/fature-moc.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 9999; /* Set a higher z-index */
}

.image-container-slider {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1; /* Set a lower z-index */
}

.carousel-image.active {
  opacity: 1;
}

.button-container {
  margin-top: 10px;
  width: 50%;
}

.buttons-div {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: auto;
  margin-bottom: auto;
  flex-wrap: wrap;
}

.button-container button {
  margin: 0 10px;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.button-container button.active {
  color: white;
}

.info-text-div {
  width: 100%;
}

.info-text {
  width: 80%;
}

.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: auto;
  margin-bottom: auto;
  border: 2px solid #3e9f36;
}

.circle-icon.active {
  background-color: #3e9f36;
}

/* Style the icon color */
.circle-icon svg {
  color: #3e9f36; /* Default color */
}

.circle-icon.active svg {
  color: #fff; /* Color when active */
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  width: 50%;
}

.button-div {
  border-radius: 10px;
  border: 2px solid #3e9f36;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 60px;
  flex-shrink: 0;
  margin-left: 20px;
  cursor: pointer;
}

.button-div img {
  width: 70%;
  height: 70%;
  flex-shrink: 0;
  object-fit: contain;
}

/* Mobile */
@media screen and (max-width: 600px) {
  .button-container {
    display: flex;
    justify-content: center;
    gap: 18px;
    width: 100%;
    margin-top: 40px;
    flex-wrap: wrap;
  }

  .button-div {
    width: 90%;
    flex-wrap: wrap;
    margin-left: 0px;
  }

  .info-text {
    width: 90%;
  }
}

/* Tablet */
@media screen and (min-width: 600px) and (max-width: 900px) {
  .button-container {
    display: flex;
    justify-content: center;
    gap: 18px;
    width: 100%;
    margin-top: 40px;
    flex-wrap: wrap;
  }

  .button-div {
    width: 90%;
    flex-wrap: wrap;
    margin-left: 0px;
  }

  .info-text {
    width: 90%;
  }
}
