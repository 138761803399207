@import url('Variables.css');

.navbar {
    background: #fff;
    height: 60px;
    width: 100%;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    z-index: 999;
    /*border-radius: var(--borderRadius);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);*/
    
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 60px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: var(--Secondary1);
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    height: 60px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid var(--Primary2);
  }

  .nav-button {
    background: var(--Primary2);
    border-radius: 4px;
    border: none;
    margin-left: 10px;
    
  }
  


  .nav-button-link {
    color: #fff;
    padding-top: 0px;
  }
  
  .nav-links {
    color: var(--Secondary1);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-size: var(--Body);
    font-weight: 400;
  }
  
  .activated {
    color: var(--Primary2);
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }

  
  img.header-logo {
    width: 25%;
    margin-top: 2px;
  }


  .button-container-tiny {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 24px;
  }

  .button-div {
    border-radius: 10px;
    border: 2px solid #3E9F36;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 180px;
    height: 60px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .custom-button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    gap: 24px;
  }

  .custom-button {
    border-radius: 50px;
    border: 2px solid #3E9F36;
    background: #3E9F36;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 20px;
  }


  .button-div:hover {
    border: 4px solid #3E9F36;
  }

  .button-div img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;    
  }

  .button-text {
    color: #5f6368;
    font-size: 20px;
    font-weight: 500;
  }

  .FoncGrid-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .FoncGrid-div {
    display: flex;
    justify-content: flex-start; /* Center horizontally */
    align-items: center; /* Center vertically */
    gap: 50px;
    /*flex-wrap: wrap;*/
  }

  .align {
    text-align: end !important;
  }
  
  .FoncGrid-rect {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid #3E9F36;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .FoncGrid-rect-img {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    object-fit: contain;
  }
  
  .FoncGrid-rect-p {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
  }

  .FoncGrid-big-container {
    display: flex;
    justify-self: center;
    gap: 20px;
    /*flex-wrap: wrap;*/
  }

  .FoncGrid-big-container-header {
    color: #000;
    text-align: start;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
  }

  .FoncGrid-container-text {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
  }


  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 60px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      height: auto;
    }
  
    .nav-menu.active {
      background: var(--Secondary2);
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: var(--Primary1);
      transform: scale(1.2);
      transition: all 0.3s ease;
    }

    .nav-button {
      background: var(--Primary2);
      border-radius: 4px;
      border: none;
      margin-bottom: 20px;
      margin-top: 20px;
      padding: 10px;
    }

    .nav-button .nav-links{
      padding: 0rem;
    }

    .nav-button-link {
        font-size: var(--Body);
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      margin-top: -5px;
    }

    img.header-logo {
      width: 25%;
      margin-top: 2px;
      margin-left: -60px;
    }
    
}


.wrapper {
  max-width: 1980px;
}


.hero-img {
  width: 100%;
  height: 100%;
}

.hero::before {
  content: "";
  background-image: url(../Assets/Hero.jpg);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-content {
  position: absolute;
  top: 50%;
  padding: 40px;
  margin-left: 40px;
  width: 80%;
}

.hero-title {
  width: 60%;
}

.hero-header {
  color: var(--Secondary1);
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 46px;
}

.guide-btn-container {
  justify-content: flex-start !important;
  margin-top: -50px;
}

.hero-paragraph {
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  color: var(--Primary1);
  margin-top: 10px;

}


.create-project {
  padding: 10px;
  border-radius: var(--borderRadius);
  background-color: var(--Primary2);
  border: none;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--Secondary2);
}

.input-big-container {
  display: flex;
  justify-content: start;
  height: 20px;
  position: absolute;
  width:60%;
  height: 50px;
  background-color: var(--Secondary2);
  border-radius: var(--borderRadius);
  margin-top: 20px;
}

.input-container {
  height: 100%;
  width: 80%;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: space-between;
}

.input-container-button {
  width: 50%;
  height: 100%;
  background-color: var(--Primary3);
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  border: none;
  color: var(--Secondary2);
  font-size: var(--body);
  cursor: pointer;
}

.search-icon {
  padding-top: 6px;
  font-size: var(--body);
  font-weight: 600;
  color: var(--Secondary2);
}

.input-container-text {
  border: none;
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
  width: 100%;
  height: 100%;
  padding: 20px;
}

.input-text {
  border-right: 2px solid #EEEEEE;
}

.input-container-location-icon {
  margin-top: 16px;
  margin-right: 10px;

}

@media screen and (max-width: 960px) {
  .hero-content {
    top: 25%;
    width: 100%;
    padding: 10%;
    margin-left: auto;
    margin-right: auto;
  }  

  .hero-title {
    width: 100%;
    
  }

  .hero-header {
    color: #000;
  }

  .input-container {
    width: 100%;
  }

  .input-big-container {
    width: 90%;
    margin-left: -20px;
    /* margin-right: auto; */
  }
}

.middle-container {
  position: absolute;
  top: calc(100% + 50px);
  padding: 40px;
  margin-left: 40px;
}

.middle-container-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: var(--Primary1);
  margin-bottom: 20px;
}

.middle-container-content {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.middle-container-steps {
  width: 30%;
  padding: 20px;
}

.middle-container-stepsTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: var(--Primary2);
  padding-bottom: 20px;
}

.middle-container-stepsParagraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--Secondary1);
}

@media screen and (max-width: 960px) {
  .middle-container {
    position: absolute;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
  } 

  .middle-container-title {
    margin-left: 10px;
    font-size: var(--Header2);
  }


  .middle-container-content {
    flex-direction: column;
  }  

  .middle-container-steps {
    width: 100%;
  }
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.image-container {
  width: 50%;
  height: auto;
}

@media screen and (max-width: 960px) {
  .image-container {
    width: 100%;
  }
}

.SideComponents {
  position: absolute;
  top: calc(100% + 350px);
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  background: rgba(34, 120, 15, 0.04);
  width: 100%;
}

.SideComponent {
  display: flex;
  justify-content:center;
  gap: 100px;
  width: 95%;
  margin-left: 40px;
  margin-right: 40px;
}

.SideComponent-paragraph {
  width: 50%;
}

.SideComponent-img {
  width: 50%;
}

.SideComponent-paragraph-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 40px;
  color: var(--Primary1);
}

.SideComponent-paragraph-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--Secondary1);
  align-items: center;
  text-align: justify;

}


@media screen and (max-width: 960px) {
  .SideComponents {
    width: 100%;
    top: calc(100% + 600px);
    padding: 20px;
    gap: 50px;
  }

  .SideComponent {
    flex-direction: column;
    margin-left: 0px;
    margin-right: 0px;
    gap: 50px;
  }

  .SideComponent-paragraph {
    width: 100%;
  }

  .SideComponent-paragraph-title {
    font-size: var(--Header2);
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .SideComponent-img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  img.SideComponent-image-container {
    width: 98%;
  }
}


.FaqContainer {
  position: absolute;
  top: calc(100% + 1250px); 
  width: 100%; 
}

.faq-bigcontainer {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  margin-bottom: 250px;
}

.faq-question {
  padding-top: 20px;
}

.question-container {
  padding-top: 20px;
  font-weight: 400;
}

.faq-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  color: var(--Primary1);
  margin-bottom: 20px;
}

.question-container {
  padding: 20px;
  border-bottom: 1px solid #E2E2E2;
}

.question-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.question-title-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}


@media screen and (max-width: 960px) {
  .FaqContainer {
    width: 100%;
    top: calc(100% + 2040px);
  }

  .faq-bigcontainer {
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-bottom: 200px;
  }
}


.footer-container-hero {
    position: absolute;
    top: calc(100% + 2200px);  
    width: 100%;
}

.footer-container {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--Primary1);
  padding: 40px;
  margin-top: 20px;
}

.footer-level2 {
  display: flex;
  justify-content: center; 

}


.footer-level1{
  display: flex;
  justify-content: start;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  gap: 150px;
}

.footer-easyaccess {
  color: var(--Secondary2);
}

.footer-easyaccess-pages {
  padding-top: 10px;
  list-style-type: none;
}

.footer-easyaccess-pages li {
  margin-top: 10px;
}

.legal-pages, .footer-Legal-pages {
  padding-top: 10px;  
}

.app-store {
  display: flex;
  justify-content: start;
  gap: 20px;
}

.social-meadia {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  gap: 20px;
  cursor: pointer;
}

img.apple-store-logo {
  width: 100%;
  margin-top: 2px;
  cursor: pointer;
}

.footer-easyaccess-2 {
  display: flex;
  justify-content: center;
  width: 100%;

}

.account-creation {
  padding-top: 10px;
}

.footer-easyaccess-3 {
  display: flex;
  justify-content: center;
  color: var(--Secondary2)
}

.copyright {
  margin-top: 20px;
  font-size: var(--body);
}


.header-logo, .footer-Legal-pages, .footer-pages, .account-creation {
  cursor: pointer;
}


@media screen and (max-width: 960px) {
  .footer-container-hero {
    top: calc(100% + 3300px);
  }  

  .footer-level1, .footer-level2 {
    flex-direction: column;
    gap: 50px;  
  }

  .footer-easyaccess {
    padding: 0px;
  }

  .footer-easyaccess-2 {
    flex-direction: column;
    gap: 50px;
  }

  .img.apple-store-logo {
    width: 20%;
    margin-top: 2px;
  }
}


.howItWorks::before {
  content: "";
  background-image: url(../Assets/Howitworks.png);
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.white {
  color: #fff;
}

.howItWorks-paragraph {
  width: 50%;
  color: #fff;
  margin-top: 20px;
  text-align: justify;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.hero-SideComponents {
  position: absolute;
  top: calc(100% + 20px);
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 150px;
  /* width: 80%; */
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 100px;
}

.footer-container-howitworks {
  position: absolute;
  top: calc(100% + 1800px);  
  width: 100%;
}


.CenterComponent {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-left: auto;
  margin-right: auto;
}

.CenterComponent-paragraph {
  width: 70%;
}

.CenterComponent-image-container {
  width: 180px;
  height: 180px;
  left: 205px;
  top: 750px;
  margin-top: 10px;
}

.CenterComponent-paragraph-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: var(--Primary2);
  margin-bottom: 10px;
}

.CenterComponent-paragraph-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: justify;
}


@media screen and (max-width: 960px) {
  .howItWorks-paragraph {
    width: 100%;
  }

  .footer-container-howitworks {
    position: absolute;
    top: calc(100% + 3200px);
    width: 100%;
  }

  .CenterComponent {
    flex-direction: column;
  }

  .CenterComponent-paragraph {
    width: 100%;
  }

  .CenterComponent-img {
    display: flex;
  }

  .CenterComponent-image-container {
    margin-left: auto;
    margin-right: auto;
  }

  .CenterComponent-paragraph-title {
    margin-bottom: 20px;
  }
}



/* Mobile */
@media screen and (max-width: 600px) {
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, 50%);
  }


  img.header-logo {
    margin-left: 10px;
  }

  .hero-header {
    font-size: 30px;
    line-height: 40px;
  }

  .footer-container-hero {
    top: calc(100% + 3350px);
  }

  .footer-container-howitworks {
    top: calc(100% + 3640px);
  }

  .hero-SideComponents{
    margin-left: 20px;
    margin-right: 20px;
    padding: 0px;
  }

  .FoncGrid-big-container{
    justify-content: center;
    flex-wrap: wrap;
  }

  .FoncGrid-big-container-header{
    text-align: center !important;
  }

  .FoncGrid-div {
    justify-content: center;
    flex-wrap: wrap;
  }

  .FoncGrid-container-text{
    justify-content: center;

  }

  .button-container-tiny, .button-div {
    flex-wrap: wrap;
    width: 100%;
  }

  .button-div {
    width: 100%;
  }

  

}


/* Tablet */
@media screen and (min-width: 600px) and (max-width: 900px) {
  .SideComponents {
    top: calc(100% + 500px);
  }

  .FaqContainer {
    width: 100%;
    top: calc(100% + 2200px);
  }

  .footer-container-hero {
    top: calc(100% + 3600px);
  }

  img.apple-store-logo {
    width: 20%;
    margin-top: 0px;
  }
}



@media screen and (min-width: 961px) and (max-width: 1488px) {
  .nav-links {
    font-size: 14px !important;
  }

  .nav-item {
    width: 140px;
  }
}
