@import url('Variables.css');


.multistep-title {
    font-size: var(--Header3);
    text-align: center;
    margin-bottom: 20px;

    font-weight: 600;
}


.login-wrapper {
    margin-top: 50px;
}

.authentifciation-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #000000;
}

select.relative.p-16.block.w-full.appearance-none.rounded.rounded-lg.border.border-gray-300.px-3.py-2.placeholder-gray-500.focus\:z-10.focus\:border-indigo-500.focus\:outline-none.focus\:ring-indigo-500.sm\:text-sm.border-red-500 {
    padding: 16px;
}

input#Assureur {
    padding: 16px;
}

input#Nom\ de\ banque {
    padding: 16px;
}

input#IBAN {
    padding: 16px;
}

input#BIC {
    padding: 16px;
}

.form-authentifciation {
    background: #FFFFFF;
    box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
}

input.margin-top.relative.p-\[16px\].block.w-full.appearance-none.rounded-lg.border.border-gray-300.px-3.py-2.text-gray-900.placeholder-gray-500.focus\:z-10.focus\:border-green-500.focus\:outline-none.focus\:ring-green-500.sm\:text-sm {
    padding: 16px;
}

input#password, input#tel, input#assurance, input#zone, input#vehicule  {
    margin-top: 16px;
    padding: 16px
}

select.form-select.relative.p-16.cursor-pointer.block.w-full.appearance-none.rounded-lg.border.border-gray-300.px-3.py-2.text-gray-900.placeholder-gray-500.focus\:z-10.focus\:border-green-500.focus\:outline-none.focus\:ring-green-500.sm\:text-sm {
    padding: 16px;
}

select.margin-top.relative.p-16.block.w-full.appearance-none.rounded-lg.border.border-gray-300.px-3.py-2.text-gray-900.placeholder-gray-500.focus\:z-10.focus\:border-green-500.focus\:outline-none.focus\:ring-green-500.sm\:text-sm.cursor-pointer {
    padding: 16px;
}

input.mb-4.relative.p-16.block.w-full.appearance-none.rounded.rounded-lg.border.border-gray-300.px-3.py-2.placeholder-gray-500.focus\:z-10.focus\:border-indigo-500.focus\:outline-none.focus\:ring-indigo-500.sm\:text-sm.border-red-500 {
    padding: 16px;
}

select.p-4.mt-1.block.w-full.rounded-md.border.border-gray-300.bg-white.py-2.px-3.shadow-sm.focus\:border-green-500.focus\:outline-none.focus\:ring-green-500.sm\:text-sm.border-red-500 {
    padding: 16px;
}

input#raison {
    padding: 16px
}

input#postale {
    padding: 16px
}

input#ville {
    padding: 16px;
}

.margin-top {
    margin-top: 16px;
}

input#email {
    padding: 16px;
}



input#email-address, input#nom, input#city, input#zip, input#departement  {
    padding: 16px;
}

.register-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #71717A;
}

.register-span {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: var(--Primary1);
}

.submit-button {
    padding: 16px;
    background: var(--Primary3);
}

.submit-button:hover {
    background: #15490a;
}

.forgot-password {
    color: var(--Primary1);
}

.forgot-password:hover {
    color: #3251a1;
}