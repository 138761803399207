@import url('Variables.css');

.userProfile-btn {
    background-color: var(--Primary3);
}

.userProfile-btn:hover {
    background-color: #15490a;
}

.userProfile-btn-disabled {
    background-color: lightgrey;
}

.userProfiledelete-btn {
    background-color: #ff0000;
}

.userProfiledelete-btn:hover {
    background-color: #961111;
}

select#zone {
    padding: 16px;
}

.fixed.md\:static.bg-red.dark\:bg-main-dark-bg.w-full {

    border-bottom: 1px solid #cccccc;
}

.ReactModal__Overlay {
    z-index: 9999;
}


.active-link {
    color: var(--Primary3);
}

.on-hover:hover  {
    color: var(--Primary3);
}

.w-72.fixed.dark\:bg-secondary-dark-bg.bg-white {
    z-index: 1;
}

.fixed.md\:static.bg-main-bg.dark\:bg-main-dark-bg.w-full {
    z-index: 1;
}

.fixed.top-0.left-22.h-full.w-\[75\%\].flex.items-center.justify-center {
    z-index: 1;
}

.fit-image {
    object-fit: contain;
}

.icon-form {
    font-size: 120px;
}

select.p-4.mt-1.block.w-full.rounded-md.border.border-gray-300.bg-white.py-2.px-3.shadow-sm.focus\:border-indigo-500.focus\:outline-none.focus\:ring-indigo-500.sm\:text-sm.false {
    padding: 16px;
}

canvas.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
}


.react-pdf__Page__textContent.textLayer {
    display: none;
}

canvas.react-pdf__Page__canvas {
    margin-bottom: -800px;
}

input[type="checkbox"]:required:not(:checked) + label:before,
input[type="radio"]:required:not(:checked) + label:before {
  border-color: red;
}

input#passwordCheck {
    padding: 16px;
}

input#adresse {
    padding: 16px;
}

input#postal {
    padding: 16px;
}

.noneiban {
    display: none;
}

.input-error {
    border-color: red;
  }
  
  .input-error + .error-message {
    display: block;
    color: red;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }

  .flex-1.bg-white.rounded-lg.shadow-xl.mt-4.p-8.overflow-y-auto.h-\[450px\].w-\[10px\] {
    width: 250px;
}

.fixed.top-10.right-20.z-50.bg-white.rounded-lg.shadow-xl.mt-4.p-8.overflow-y-auto.h-\[350px\] {
    width: 550px;
}


